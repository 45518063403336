import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { MdModeEdit } from "react-icons/md";

import { LIST_USERS } from "../../../graphql/queries";

import MainTemplate from "../../../templates/admin/MainTemplate";
import DataTable from "../../../components/DataTable";
import { routes } from "../../../routes";
import {
  permissionTranslations,
  UserPermission,
} from "../../../util/variables";
import AddButton from "../../../components/AddButton";
import { useCheckPermission } from "../../../hooks/useCheckPermission";

const OptionsPanel = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
`;
const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;

export default function Users() {
  const [users, setUsers] = useState([]);
  const { isGuardian, isAdmin } = useCheckPermission();

  const { loading } = useQuery(LIST_USERS, {
    variables: {
      filter: {
        permission: isGuardian ? UserPermission.GUARDIAN : null,
      },
    },
    onCompleted: (data) => setUsers(data.listUsers),
    onError: (err) => {
      console.log(err);
    },
  });

  const columns = useMemo(() => {
    let cols = [
      {
        Header: "Nazwa użytkownika",
        accessor: "username",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Uprawnienia",
        accessor: "permission",
      },
      {
        accessor: "edit",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ];
    if (isGuardian) {
      cols = cols.filter((obj) => obj.accessor !== "edit");
    }

    return cols;
  }, [isGuardian]);

  const data = users.reduce((acc, user) => {
    acc = [
      ...acc,
      {
        username: user.username,
        email: user.email,
        permission: permissionTranslations[user.permission],
        edit: (
          <Link to={`${routes.admin.user}/${user.id}`}>
            <MdModeEdit size={22} />
          </Link>
        ),
      },
    ];
    return acc;
  }, []);

  return (
    <MainTemplate name="Admini i opiekunowie">
      {isAdmin && (
        <OptionsPanel>
          <AddButton as={Link} to={`${routes.admin.user}/create`} />
        </OptionsPanel>
      )}
      <DataTable data={data} columns={columns} loading={loading} />
    </MainTemplate>
  );
}
