export const theme = {
  color: {
    black: "rgb(0, 0, 0)",
    white: "rgb(255, 255, 255)",
    lightGrey: "#E1E1E1",
    grey: "#8C8C8C",
    greyOpacity10: "rgba(140, 140, 140, 0.1)",
    darkGrey: "#242424",
    primaryBlue: "#0C4DA2",
    lightBlue: "#039BD4",
    lightBlueOpacity10: "rgba(3, 156, 212, 0.1)",
    red: "#ff0033",
    lightRed: "#ff617b",
  },
  size: {
    xs: "10px",
    s: "12px",
    m: "14px",
    l: "16px",
    xl: "18px",
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};
