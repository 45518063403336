import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { theme } from "../theme/theme";

export default function Loader({
  loading,
  size = 20,
  color = theme.color.primaryBlue,
}) {
  return <ClipLoader loading={loading} size={size} color={color} />;
}
