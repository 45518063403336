import React, { useMemo, useEffect } from "react";
import styled, { css, useTheme } from "styled-components";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { addMonths, format, differenceInDays, isAfter } from "date-fns";

import { setProceedingsList } from "../../redux/actions";
import { LIST_PROCEEDINGS } from "../../graphql/queries";

import { ProceedingStatus } from "../../util/variables";
import MainTemplate from "../../templates/reseller/MainTemplate";
import DataTable from "../../components/DataTable";
import StatusItem from "../../components/StatusItem";
import { useProceedingState } from "../../context/proceedingState";
import { usePagination } from "../../hooks/usePagination";
import Pagination from "../../components/Pagination";
import { useAuthResellerState } from "../../context/authReseller";
import ResellerProceedingFiltration from "../../components/ResellerProceedingFiltration";
import { FcClock } from "react-icons/fc";
import { FaWindowClose } from "react-icons/fa";
import useUpdateProceedingStatus from "../../hooks/useUpdateProceedingStatus";
import { useProceedingExtension } from "../../hooks/useProceedingExtension";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { routes } from "../../routes";
import { truncateText } from "../../util/functions";

const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;
const IconBox = styled.div`
  display: flex;
  cursor: pointer;

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
    `}
`;
const CTAButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > div:not(:last-of-type) {
    margin-right: 14px;
  }
`;

export default function Home() {
  const { reseller } = useAuthResellerState();

  const columns = useMemo(() => {
    const flexEndCell = ({ cell: { value } }) => (
      <FlexEndCell>{value}</FlexEndCell>
    );

    let cols = [
      {
        Header: "End user",
        accessor: "clientName",
      },
      {
        Header: "End user NIP",
        accessor: "clientNip",
      },
      {
        Header: "Data zgłoszenia",
        accessor: "createdAt",
      },
      {
        Header: "Data wygaśnięcia",
        accessor: "expirationDate",
      },
      {
        Header: "Dni do końca",
        accessor: "expirationDays",
      },
      {
        Header: "Dotyczy",
        accessor: "section",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        accessor: "ctaButtons",
        Cell: flexEndCell,
      },
    ];

    return cols;
  }, []);

  const { paginationState, paginationDispatch } = usePagination({ limit: 25 });

  const dataDispatch = useDispatch();
  const proceedingState = useProceedingState();

  useEffect(() => {
    paginationDispatch({ type: "RESET" });
  }, [proceedingState, paginationDispatch]);

  const { loading } = useQuery(LIST_PROCEEDINGS, {
    variables: {
      filter: {
        status: proceedingState.status.id,
        sectionId: proceedingState.section.id,
        resellerId: reseller.id,
        input: proceedingState.input,
        userId: proceedingState.user.id,
      },
      offset: paginationState.offset,
      limit: paginationState.limit,
      order: proceedingState.order,
    },
    onError: (err) => {
      console.error(err);
    },
    onCompleted(data) {
      const { items, count } = data.listProceedings;
      dataDispatch(setProceedingsList(items));
      paginationDispatch({ type: "COUNT", payload: count });
    },
  });

  const proceedings = useSelector((state) => state.proceedings);

  const data = proceedings?.reduce((acc, proceeding) => {
    const status = <StatusItem value={proceeding.status} />;

    const createdAt = new Date(parseInt(proceeding.createdAt));
    const expirationDate = addMonths(
      parseInt(proceeding.createdAt),
      proceeding.section.expirationTime * proceeding.extensionCount
    );
    const expirationDays = differenceInDays(expirationDate, new Date(), {
      unit: "d",
    });
    const isRejectedOrClosed =
      proceeding.status === ProceedingStatus.CLOSED ||
      proceeding.status === ProceedingStatus.REJECTED;

    acc = [
      ...acc,
      {
        status,
        clientName: (
          <Link
            to={`${routes.reseller.proceeding}/${proceeding.id}/edit`}
            title={proceeding.clientName}
          >
            {truncateText(proceeding.clientName)}
          </Link>
        ),
        clientNip: proceeding.clientNip,
        section: proceeding.section.name,
        createdAt: format(createdAt, "dd-MM-yyyy HH:mm"),
        expirationDate: format(expirationDate, "dd-MM-yyyy HH:mm"),
        expirationDays: isRejectedOrClosed
          ? "-"
          : expirationDays >= 0
          ? `${expirationDays} dni`
          : "-",
        ctaButtons: (
          <CTAButtons
            proceeding={proceeding}
            expirationDate={expirationDate}
            isRejectedOrClosed={isRejectedOrClosed}
          />
        ),
      },
    ];
    return acc;
  }, []);

  return (
    <MainTemplate name="Wszystkie projekty">
      <ResellerProceedingFiltration />
      <DataTable data={data} columns={columns} loading={loading} />
      <Pagination
        paginationState={paginationState}
        paginationDispatch={paginationDispatch}
      />
    </MainTemplate>
  );
}

const CTAButtons = ({ proceeding, expirationDate, isRejectedOrClosed }) => {
  const theme = useTheme();
  const { id, extensionCount } = proceeding;

  const updateProceedingStatus = useUpdateProceedingStatus();
  const { extensionProceeding, extensionLoading } = useProceedingExtension();

  const expirationDays = differenceInDays(expirationDate, new Date(), {
    unit: "d",
  });
  const isExpirationButtonDisplayed =
    expirationDays <= 7 && isAfter(expirationDate, new Date());

  const handleCloseProjectClick = () => {
    if (
      window.confirm(
        "Czy na pewno chcesz zamknąć projekt? Ta akcja będzie nieodwracalna!"
      )
    ) {
      updateProceedingStatus({
        id,
        status: ProceedingStatus.CLOSED,
      });
    }
  };

  const handleExtensionProceedingClick = () => {
    if (window.confirm("Czy na pewno chcesz przedłużyć projekt?")) {
      extensionProceeding(id);
    }
  };

  return (
    <CTAButtonsContainer>
      {isExpirationButtonDisplayed && extensionCount < 4 ? (
        <IconBox
          isLoading={extensionLoading}
          title="Przedłuż projekt"
          onClick={handleExtensionProceedingClick}
        >
          <FcClock size={22} />
        </IconBox>
      ) : null}

      {!isRejectedOrClosed ? (
        <IconBox title="Zamknij projekt" onClick={handleCloseProjectClick}>
          <FaWindowClose size={20} color={theme.color.red} />
        </IconBox>
      ) : null}
    </CTAButtonsContainer>
  );
};
