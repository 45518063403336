import { useAuthState } from "../context/auth";
import { UserPermission } from "../util/variables";

export const useCheckPermission = () => {
  const { user } = useAuthState();
  const isAdmin = user.permission === UserPermission.ADMIN;
  const isGuardian = user.permission === UserPermission.GUARDIAN;

  return {
    isAdmin,
    isGuardian,
  };
};
