import React from "react";
import styled from "styled-components";
import { FiLogOut } from "react-icons/fi";

import { useAuthDispatch, useAuthState } from "../../context/auth";
import Navigation from "../../components/admin/Navigation";

const iconStyles = {
  width: "20px",
  height: "20px",
  cursor: "pointer",
  marginLeft: "16px",
};

const Container = styled.div`
  width: 100vw;
`;
const TopBox = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.color.primaryBlue};
  padding-left: calc(240px + 14px);
  padding-right: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  color: #fff;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  z-index: 2;
`;
const BottomBox = styled.div`
  display: flex;
`;
const Content = styled.div`
  width: calc(100% - 240px);
  padding: 40px 36px;
`;
const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.5px;
`;

export default function MainTemplate({ name, children }) {
  const dispatch = useAuthDispatch();
  const { user } = useAuthState();

  return (
    <Container>
      <TopBox>
        {name}
        <ProfileSection>
          {`Witaj, ${user.username}!`}
          <FiLogOut
            style={iconStyles}
            color="#fff"
            onClick={() => dispatch({ type: "LOGOUT" })}
          />
        </ProfileSection>
      </TopBox>
      <BottomBox>
        <Navigation />
        <Content>{children}</Content>
      </BottomBox>
    </Container>
  );
}
