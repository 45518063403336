export const ProceedingStatus = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  WAITING: "WAITING",
  REJECTED: "REJECTED",
};

export const ProceedingStatusTranslations = {
  OPEN: { color: "green", name: "Otwarty" },
  CLOSED: { color: "purple", name: "Zamknięty" },
  WAITING: { color: "#cccc00", name: "Oczekujący" },
  REJECTED: { color: "red", name: "Odrzucony" },
};

export const ProceedingStatusArray = [
  { id: "OPEN", name: "Otwarty" },
  { id: "CLOSED", name: "Zamknięty" },
  { id: "WAITING", name: "Oczekujący" },
  { id: "REJECTED", name: "Odrzucony" },
];

export const UserPermission = {
  ADMIN: "ADMIN",
  GUARDIAN: "GUARDIAN",
};

export const permissionTranslations = {
  ADMIN: "Administrator",
  GUARDIAN: "Opiekun",
};
