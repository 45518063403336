import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import { CREATE_PROCEEDING, UPDATE_PROCEEDING } from "../../graphql/mutations";
import {
  GET_PROCEEDING,
  IS_PROCEEDING_WAITING,
  LIST_SECTIONS,
} from "../../graphql/queries";

import MainTemplate from "../../templates/reseller/MainTemplate";
import { ValidationInput } from "../../components/Input";
import Button from "../../components/Button";
import Select from "../../components/Select";
import { routes } from "../../routes";
import { useAuthResellerState } from "../../context/authReseller";
import UploadFiles from "../../components/UploadFiles";
import { isUUID } from "../../util/validators";

const Form = styled.form``;
const InputBox = styled.div`
  position: relative;
  width: calc(50% - 10px);
`;
const InputsBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Warning = styled.div`
  background-color: #ffa500;
  border-radius: 4px;
  padding: 10px 14px;
  color: #4d3626;
  margin-bottom: 20px;
  font-weight: ${({ theme }) => theme.weight.bold};
`;

export default function Proceeding({ match }) {
  const history = useHistory();
  const { reseller } = useAuthResellerState();

  const [isEdit, setIsEdit] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    formState: { errors, dirtyFields },
  } = useForm();

  useEffect(() => {
    if (isUUID(match.params.id)) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
      reset();
    }
  }, [match.params.id, reset]);

  const [sections, setSections] = useState([]);
  const [listSections, { loading: sectionListLoading }] = useLazyQuery(
    LIST_SECTIONS,
    {
      onCompleted: ({ listSections }) => {
        const sections = listSections.map((section) => ({
          id: section.id,
          name: section.name,
        }));
        setSections(sections);
      },
      onError: (err) => console.log(err),
    }
  );

  const [isProceedingWaiting, { data: isWaiting }] = useLazyQuery(
    IS_PROCEEDING_WAITING
  );

  const [createProceedingMutation, { loading: createLoading }] = useMutation(
    CREATE_PROCEEDING,
    {
      onError: (err) => {
        const errors = err.graphQLErrors[0].extensions.exception.errors;
        if (errors) {
          for (const item of Object.entries(errors)) {
            const [key, message] = item;
            setError(key, { type: "manual", message });
          }
        } else {
          console.log(err);
        }
      },
      onCompleted() {
        toast.success("Dodano nowy projekt!", {
          autoClose: 3000,
        });
        history.push(routes.reseller.home);
      },
    }
  );

  const [updateProceedingMutation, { loading: updateLoading }] = useMutation(
    UPDATE_PROCEEDING,
    {
      onError: (err) => {
        const errors = err.graphQLErrors[0].extensions.exception.errors;
        if (errors) {
          for (const item of Object.entries(errors)) {
            const [key, message] = item;
            setError(key, { type: "manual", message });
          }
        } else {
          console.log(err);
        }
      },
      onCompleted() {
        toast.success("Zapisano!", {
          autoClose: 3000,
        });
      },
    }
  );

  const { data, loading: fetchLoading } = useQuery(GET_PROCEEDING, {
    variables: { id: match.params.id },
  });

  useEffect(() => {
    if (!fetchLoading && isEdit) {
      const { clientName, clientNip, section, comments } = data.getProceeding;

      setValue("clientName", clientName);
      setValue("nip", clientNip);
      setValue("section", { id: section.id, name: section.name });
      setValue("comment", comments[0]?.message || "");
    }
  }, [fetchLoading, data, isEdit, setValue]);

  const handleNipChange = (e) => {
    const clientNip = e.target.value;
    isProceedingWaiting({ variables: { clientNip } });
  };

  const onSubmit = (data) => {
    const variables = {
      clientName: data.clientName,
      clientNip: data.nip,
      comment: data.comment,
      resellerId: reseller.id,
      sectionId: data.section.id,
      files: data.files,
    };
    if (isEdit) {
      updateProceedingMutation({
        variables: {
          id: match.params.id,
          comment: dirtyFields.comment ? data.comment : undefined,
        },
      });
    } else {
      createProceedingMutation({ variables });
    }
  };

  return (
    <MainTemplate name="Dodaj nowy projekt">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsBox>
          <InputBox>
            <ValidationInput
              placeholder="Wprowadź nazwę end usera"
              label="Nazwa end usera"
              {...register("clientName", {
                required: { value: true, message: "Pole nie może byc puste" },
              })}
              error={errors?.clientName?.message}
              disabled={isEdit}
            />
          </InputBox>
          <InputBox>
            <ValidationInput
              placeholder="Wprowadź nip end usera"
              label="Nip end usera"
              {...register("nip", {
                required: { value: true, message: "Pole nie może byc puste" },
              })}
              onChange={handleNipChange}
              error={errors?.nip?.message}
              disabled={isEdit}
            />
          </InputBox>
        </InputsBox>
        <InputsBox>
          <InputBox>
            <Controller
              render={({ ref, ...rest }) => (
                <Select
                  items={sections}
                  getItems={listSections}
                  isListLoading={sectionListLoading}
                  label="Sekcja"
                  placeholder="Wybierz sekcje"
                  error={errors?.section?.message}
                  disabled={isEdit}
                  {...rest}
                />
              )}
              rules={{
                required: { value: true, message: "Pole nie może byc puste" },
              }}
              control={control}
              name="section"
            />
          </InputBox>
          <InputBox>
            <ValidationInput
              as="textarea"
              rows="4"
              placeholder="Wprowadź komentarz"
              label="Komentarz"
              {...register("comment", {
                minLength: {
                  value: 10,
                  message: "Komentarz musi mieć przynajmniej 10 znaków",
                },
              })}
              error={errors?.comment?.message}
            />
          </InputBox>
        </InputsBox>
        <InputsBox>
          <InputBox></InputBox>
          <InputBox>
            <Controller
              render={({ ref, ...rest }) => (
                <UploadFiles
                  label="Pliki"
                  error={errors?.files?.message}
                  disabled={isEdit}
                  {...rest}
                />
              )}
              control={control}
              name="files"
            />
          </InputBox>
        </InputsBox>
        {isWaiting?.isProceedingWaiting && (
          <Warning>
            UWAGA! NIP jest już przypisany do projektu. Ten projekt będzie
            musiał zatwierdzić pracownik EPSON.
          </Warning>
        )}
        <ButtonBox>
          <Button
            value={isEdit ? "Zapisz" : "Dodaj projekt"}
            input
            isLoading={createLoading || updateLoading}
            margin="0 0 0 25px"
          />
        </ButtonBox>
      </Form>
    </MainTemplate>
  );
}
