import React from "react";

export default function EpsonLogo({ width = 80 }) {
  return (
    <svg
      width={`${width}px`}
      viewBox="0 0 133 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Panel-administracyjny"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Logowanie"
          transform="translate(-894.000000, -364.000000)"
          fill="#084DA5"
          fillRule="nonzero"
        >
          <g id="Login-form" transform="translate(730.000000, 307.000000)">
            <g id="EPSON-Logo" transform="translate(164.000000, 57.000000)">
              <path
                d="M85.9994676,0 C77.756962,0 73,6.32811721 73,14.9995325 C73,23.671875 77.7570152,30 85.9994676,30 C94.241593,30 99,23.6718828 99,14.9995325 C99,6.328125 94.2416386,0 85.9994676,0 L85.9994676,0 Z M85.9994676,24.9537691 C80.7649993,24.9537691 78.7459182,20.0161938 78.7459182,14.9995325 C78.7459182,9.98280886 80.7649993,5.04521796 85.9994676,5.04521796 C91.2348714,5.04521796 93.254021,9.98279328 93.254021,14.9995325 C93.254021,20.016186 91.2348714,24.9537691 85.9994676,24.9537691 Z"
                id="path3336"
              ></path>
              <polygon
                id="polygon3338"
                points="0 1 21 1 21 6.16910994 5.84329251 6.16910994 5.84329251 12.6007644 19.9784662 12.6007644 19.9784662 17.6417025 5.84329251 17.6417025 5.84329251 24.830914 21 24.830914 21 30 0 30"
              ></polygon>
              <path
                d="M24,30 L29.8184349,30 L29.8184349,21.3005586 L35.0906692,21.3005586 C42.2135408,21.3005586 47,17.6705795 47,11.1502394 C47,4.62963598 42.2135408,1 35.0906692,1 L24,1 L24,30 L24,30 Z M35.0907077,16.1338763 L29.8184734,16.1338763 L29.8184734,6.16668226 L35.0907077,6.16668226 C38.7484845,6.16668226 41.1816421,7.81711452 41.1816421,11.1502394 C41.1816421,14.483085 38.7484845,16.1338763 35.0907077,16.1338763 L35.0907077,16.1338763 Z"
                id="path3340"
              ></path>
              <path
                d="M107.098121,1 L101,1 L101,30 L106.817952,30 L106.817952,18.2525456 C106.817952,15.0406752 106.703242,12.1864613 106.596973,9.66545519 C107.283518,11.1163245 109.363648,15.4793934 110.331675,17.1178004 L117.905274,30 L124,30 L124,1 L118.182394,1 L118.182394,12.4554118 C118.182394,15.6658778 118.296758,18.8145521 118.40434,21.3345448 C117.716828,19.8847367 115.634969,15.521588 114.666935,13.8832528 L107.098113,1 L107.098121,1 Z"
                id="path3342"
              ></path>
              <path
                d="M53.8697036,19.8132331 C53.8697036,23.6238429 57.0253687,25.1084425 60.3813651,25.1084425 C62.5756588,25.1084425 66.1322662,24.4662053 66.1322662,21.5383059 C66.1322662,18.4491783 61.7828146,17.8663866 57.5891763,16.7428675 C53.1902516,15.5759812 48.9597996,13.9157211 48.9597996,8.66281008 C48.9597996,2.80831415 54.4689734,0 59.6206823,0 C65.5708895,0 71.0398946,2.60681589 71.0398946,9.22582564 L65.1719278,9.22582564 C64.9716624,5.77526653 62.3372868,4.89256391 59.3421415,4.89256391 C57.3437988,4.89256391 54.8274558,5.73492631 54.8274558,8.14154676 C54.8274558,10.3479718 56.2620142,10.6902902 63.4110096,12.5352837 C65.4869405,13.0555337 72,14.3188424 72,20.8168471 C72,26.0697894 67.8889205,30 60.1396457,30 C53.8306455,30 47.921873,26.8729349 48.0007815,19.8132955 L53.8697735,19.8132955 L53.8697036,19.8132331 Z"
                id="path3344"
              ></path>
              <path
                d="M127.272333,4.00066399 C127.272333,2.49402222 128.494104,1.27363885 130.000499,1.27363885 C131.506233,1.27363885 132.726684,2.49402222 132.726684,4.00066399 C132.726684,5.50697564 131.506233,6.72768913 130.000499,6.72768913 C128.494104,6.72768913 127.272333,5.50697564 127.272333,4.00066399 Z M130.000499,7 C131.656408,7 133,5.65747251 133,4.00066399 C133,2.34352535 131.656408,1 130.000499,1 C128.342271,1 127,2.34351785 127,4.00066399 C127,5.65747251 128.342271,7 130.000499,7 Z"
                id="path3346"
              ></path>
              <path
                d="M130.575479,3.90782976 C130.897145,3.90782976 131.176379,3.66139092 131.176379,3.22805352 C131.176379,2.7917051 130.897145,2.54526626 130.575479,2.54526626 L129.631588,2.54376084 L129.631588,6 L129,6 L129,2 L130.575479,2 C131.321325,2 131.806602,2.54263213 131.806602,3.22805019 C131.806602,3.8247268 131.407509,4.27755808 130.984284,4.35449118 L132,6 L131.244791,6 L130.025087,3.90782805 L130.575487,3.90782805 L130.575479,3.90782976 Z"
                id="path3348"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
