import { gql } from "@apollo/client";

export const CREATE_PROCEEDING = gql`
  mutation createProceeding(
    $status: ProceedingStatus
    $clientNip: String!
    $clientName: String!
    $comment: String
    $files: [Upload]
    $resellerId: String!
    $sectionId: String!
  ) {
    createProceeding(
      status: $status
      clientName: $clientName
      clientNip: $clientNip
      comment: $comment
      files: $files
      resellerId: $resellerId
      sectionId: $sectionId
    ) {
      id
    }
  }
`;

export const UPDATE_PROCEEDING = gql`
  mutation updateProceeding(
    $id: String!
    $status: ProceedingStatus
    $clientNip: String
    $clientName: String
    $comment: String
    $resellerId: String
    $sectionId: String
  ) {
    updateProceeding(
      id: $id
      status: $status
      clientName: $clientName
      clientNip: $clientNip
      comment: $comment
      resellerId: $resellerId
      sectionId: $sectionId
    ) {
      id
      status
    }
  }
`;
export const EXTENSION_PROCEEDING = gql`
  mutation extensionProceeding($id: String!) {
    extensionProceeding(id: $id) {
      id
    }
  }
`;

export const CREATE_SECTION = gql`
  mutation createSection($name: String!, $expirationTime: Int!) {
    createSection(name: $name, expirationTime: $expirationTime) {
      id
      name
      expirationTime
    }
  }
`;

export const UPDATE_SECTION = gql`
  mutation updateSection($id: String!, $name: String!, $expirationTime: Int!) {
    updateSection(id: $id, name: $name, expirationTime: $expirationTime) {
      id
      name
      expirationTime
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $username: String!
    $email: String!
    $permission: UserPermission!
  ) {
    createUser(username: $username, email: $email, permission: $permission) {
      id
      username
      email
      permission
      token
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser(
    $id: String!
    $username: String!
    $email: String!
    $permission: UserPermission!
  ) {
    updateUser(
      id: $id
      username: $username
      email: $email
      permission: $permission
    ) {
      id
      username
      email
      permission
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($id: String!) {
    removeUser(id: $id)
  }
`;

export const FORCE_CHANGE_USER_PASSWORD = gql`
  mutation forceChangeUserPassword(
    $email: String!
    $password: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) {
    forceChangeUserPassword(
      email: $email
      password: $password
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
    ) {
      id
    }
  }
`;
export const SEND_USER_PASSWORD = gql`
  mutation sendUserPassword($email: String!) {
    sendUserPassword(email: $email) {
      id
    }
  }
`;

export const CREATE_RESELLER = gql`
  mutation createReseller(
    $name: String!
    $email: String
    $nip: String!
    $userId: String!
  ) {
    createReseller(name: $name, email: $email, nip: $nip, userId: $userId) {
      id
    }
  }
`;

export const UPDATE_RESELLER = gql`
  mutation updateReseller(
    $id: String!
    $name: String
    $email: String
    $nip: String
    $userId: String
    $password: String
  ) {
    updateReseller(
      id: $id
      name: $name
      email: $email
      nip: $nip
      userId: $userId
      password: $password
    ) {
      id
      name
      email
      nip
    }
  }
`;

export const REMOVE_RESELLER = gql`
  mutation removeReseller($id: String!) {
    removeReseller(id: $id)
  }
`;

export const UPDATE_RESELLER_EMAIL = gql`
  mutation updateResellerEmail($id: String!, $email: String!) {
    updateResellerEmail(id: $id, email: $email) {
      id
      name
      email
      nip
    }
  }
`;

export const FORCE_CHANGE_RESELLER_PASSWORD = gql`
  mutation forceChangeResellerPassword(
    $nip: String!
    $password: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) {
    forceChangeResellerPassword(
      nip: $nip
      password: $password
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
    ) {
      id
    }
  }
`;

export const SEND_RESELLER_PASSWORD = gql`
  mutation sendResellerPassword($email: String!) {
    sendResellerPassword(email: $email) {
      id
    }
  }
`;
