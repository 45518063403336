import styled from "styled-components";

export const Label = styled.label`
  display: block;
  margin-bottom: 6px;
  margin-left: 3px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
`;
