export const routes = {
  home: "/",
  login: "/login",
  admin: {
    login: "/admin/login",
    forceChangePassword: "/admin/force-change-password",
    recoveryPassword: "/admin/recovery-password",
    sections: "/admin/sections",
    section: "/admin/section",
    proceedings: "/admin/proceedings",
    proceedingsWaiting: "/admin/proceedingsWaiting",
    proceeding: "/admin/proceeding",
    resellers: "/admin/resellers",
    reseller: "/admin/reseller",
    users: "/admin/users",
    user: "/admin/user",
  },
  reseller: {
    login: "/reseller/login",
    home: "/reseller/home",
    proceeding: "/reseller/proceeding",
    settings: "/reseller/settings",
    forceChangePassword: "/reseller/force-change-password",
    recoveryPassword: "/reseller/recovery-password",
  },
};
