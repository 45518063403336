import React, { createContext, useReducer, useContext } from "react";

const defaultState = {
  input: "",
  section: { name: "Filtruj po sekcji", id: null },
  status: { name: "Filtruj po statusie", id: null },
  reseller: { name: "Filtruj po resellerze", id: null },
  user: { name: "Filtruj po opiekunie", id: null },
  order: ["createdAt", "DESC"],
};

const ProceedingStateContext = createContext();
const ProceedingDispatchContext = createContext();

const proceedingReducer = (state, action) => {
  switch (action.type) {
    case "RESET":
      return {
        ...state,
        [action.payload]: defaultState[action.payload],
      };
    case "SET_ORDER": {
      const [fieldName, order] = state.order;
      const isTheSameName = fieldName === action.payload;
      const finalOrder = isTheSameName
        ? order === "DESC"
          ? "ASC"
          : "DESC"
        : "ASC";

      return {
        ...state,
        order: [action.payload, finalOrder],
      };
    }
    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

export const ProceedingStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(proceedingReducer, { ...defaultState });

  return (
    <ProceedingDispatchContext.Provider value={dispatch}>
      <ProceedingStateContext.Provider value={state}>
        {children}
      </ProceedingStateContext.Provider>
    </ProceedingDispatchContext.Provider>
  );
};

export const useProceedingState = () => useContext(ProceedingStateContext);
export const useProceedingDispatch = () =>
  useContext(ProceedingDispatchContext);
