import React, { useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import { ValidationInput } from "../../components/Input";
import { useAuthResellerState } from "../../context/authReseller";
import MainTemplate from "../../templates/reseller/MainTemplate";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_RESELLER_EMAIL } from "../../graphql/mutations";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { GET_RESELLER } from "../../graphql/queries";

const InputBox = styled.div`
  width: 40%;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
`;

const Settings = () => {
  const { reseller } = useAuthResellerState();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const { data: dbReseller, loading: getLoading } = useQuery(GET_RESELLER, {
    variables: { id: reseller.id },
  });

  useEffect(() => {
    setValue("email", dbReseller?.getReseller.email);
  }, [setValue, dbReseller]);

  const [changeEmail, { loading }] = useMutation(UPDATE_RESELLER_EMAIL, {
    onCompleted: () => {
      toast.success("Zapisano pomyślnie!");
    },
    onError: (err) => {
      const errors = err.graphQLErrors[0].extensions.exception.errors;
      if (errors) {
        for (const item of Object.entries(errors)) {
          const [key, message] = item;
          setError(key, { type: "manual", message });
        }
      } else {
        console.log(err);
      }
      console.log(err);
    },
  });

  const onSubmit = ({ email }) => {
    changeEmail({ variables: { id: reseller.id, email } });
  };

  return (
    <MainTemplate name="Ustawienia">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputBox>
          <ValidationInput
            placeholder="Wprowadź nowy adres e-mail"
            label="Adres e-mail"
            {...register("email", {
              required: { value: true, message: "Pole nie może byc puste" },
            })}
            isLoading={getLoading}
            error={errors?.email?.message}
          />
        </InputBox>
        <ButtonBox>
          <Button value="Zapisz" input isLoading={loading} />
        </ButtonBox>
      </form>
    </MainTemplate>
  );
};

export default Settings;
