import React from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { SEND_RESELLER_PASSWORD } from "../../graphql/mutations";

import { routes } from "../../routes";

import { ValidationInput } from "../../components/Input";
import Button, { COLOR } from "../../components/Button";
import { Title } from "../../components/reseller/Title";

import sideImage from "../../assets/images/reseller_side_background.png";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;
const SideBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 560px;
`;
const BackgroundSide = styled.div`
  width: calc(100% - 560px);
  background-image: url(${sideImage});
  background-repeat: no-repeat;
  background-size: cover;
`;
const FormBox = styled.div`
  margin-left: auto;
  margin-right: 30px;
`;
const Form = styled.form`
  margin-bottom: 100px;
  width: 400px;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const InputBox = styled.div`
  margin-bottom: 24px;
`;
const StyledTitle = styled(Title)`
  margin-bottom: 42px;
`;

export default function RecoveryPassword() {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const [setNewPassword, { loading }] = useMutation(SEND_RESELLER_PASSWORD, {
    onError: (err) => {
      const errors = err.graphQLErrors[0].extensions.exception.errors;
      if (errors) {
        for (const item of Object.entries(errors)) {
          const [key, message] = item;
          setError(key, { type: "manual", message });
        }
      } else {
        console.log(err);
      }
    },
    onCompleted: () => {
      toast.success("Nowe hasło zostało wysłane na Twojego maila");
      history.push(routes.reseller.login);
    },
  });

  const onSubmit = (data) => {
    setNewPassword({ variables: data });
  };

  return (
    <Container>
      <SideBox>
        <FormBox>
          <StyledTitle>Resetowanie hasła</StyledTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputBox>
              <ValidationInput
                {...register("email", {
                  required: { value: true, message: "Pole nie może być puste" },
                })}
                placeholder="Email"
                error={errors?.email?.message}
              />
            </InputBox>
            <ButtonBox>
              <Button color={COLOR.GREY} as={Link} to={routes.reseller.login}>
                Wróć
              </Button>
              <Button value="Wyślij nowe hasło" input isLoading={loading} />
            </ButtonBox>
          </Form>
        </FormBox>
      </SideBox>
      <BackgroundSide />
    </Container>
  );
}
