import React from "react";
import styled from "styled-components";
import { IoMdAdd } from "react-icons/io";

const Button = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.color.primaryBlue};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  transition: 0.2s ease-in-out;

  :hover {
    transform: scale(0.97);
    cursor: pointer;
  }
`;

const AddButton = (props) => {
  return (
    <Button {...props}>
      <IoMdAdd size={34} color="white" />
    </Button>
  );
};
export default AddButton;
