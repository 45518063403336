import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { useDetectOutsideClick } from "../hooks/useDetectOutsideClick";
import { useFocus } from "../hooks/useFocus";

// Animations
const enterAnimation = (node, done) => {
  gsap.from(node, {
    autoAlpha: 0,
    duration: 0.3,
    ease: "Power2.inOut",
  });
};

const exitAnimation = (node, done) => {
  gsap.timeline().to(node, {
    autoAlpha: 0,
    duration: 0.3,
    onComplete: done,
    ease: "Power2.inOut",
  });
};

const Container = styled.div`
  position: relative;
  z-index: 4;
  min-width: 160px;
  width: fit-content;
  box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  height: 40px;
  font-size: 14px;

  :not(:last-of-type) {
    margin-right: 14px;
  }
`;

const Selected = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.grey};
  margin-left: 7px;
`;

const Text = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  padding: 6px 44px 6px 8px;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.color.grey};
`;

const IconContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
`;

const Options = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 100%;
  transform: translateY(100%);
  display: flex;
  flex-direction: column;
  transform-origin: top;
  max-height: 200px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.16);
  overflow-y: auto;
  outline: none;
`;

const Option = styled.div`
  width: 100%;
  padding: 6px 20px;
  white-space: nowrap;
  transition: background 0.14s ease-in-out;
  cursor: pointer;
  user-select: none;
  letter-spacing: 1px;

  :hover {
    background-color: ${({ theme }) => theme.color.lightBlueOpacity10};
  }
`;

const SwitchDropdownSearchInput = styled.input`
  position: sticky;
  top: 0;
  outline: none;
  border: none;
  padding: 8px 20px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  font-size: ${({ theme }) => theme.size.m};
  box-shadow: 0px -7px 11px 6px rgba(0, 0, 0, 0.2);
`;

const SwitchDropdown = ({
  items,
  async,
  currentOption,
  setCurrentOption,
  reset,
  searchable,
}) => {
  const { fetchItems } = async || {};

  // Fetch items
  useEffect(() => {
    if (fetchItems) fetchItems();
  }, [fetchItems]);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  // Input auto focus after drawer open
  const [inputRef, setInputFocus] = useFocus();
  useEffect(() => {
    if (!inputRef.current) return;
    setTimeout(() => isOpen && setInputFocus(), 300);
  }, [inputRef, isOpen, setInputFocus]);

  // Close when click outside
  const picker = useRef(null);
  useDetectOutsideClick(picker, setIsOpen);

  const handleOptionChange = (item) => {
    setCurrentOption(item);
    setSearchValue("");
    setIsOpen(false);
  };

  return (
    <Container ref={picker}>
      <Text onClick={() => setIsOpen(!isOpen)}>
        <Selected>{currentOption && currentOption.name}</Selected>
      </Text>
      {currentOption.id && (
        <IconContainer onClick={reset}>
          <AiOutlineCloseCircle size={16} />
        </IconContainer>
      )}
      <Transition
        timeout={300}
        mountOnEnter
        unmountOnExit
        in={isOpen}
        onEnter={(node, done) => {
          enterAnimation(node, done);
        }}
        onExit={(node, done) => {
          exitAnimation(node, done);
        }}
      >
        <Options>
          {searchable && (
            <SwitchDropdownSearchInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Szukaj"
              ref={inputRef}
            />
          )}
          {items
            .filter(({ name }) =>
              name.toLowerCase().includes(searchValue.toLowerCase())
            )
            .map(({ name, id }) => (
              <Option key={id} onClick={() => handleOptionChange({ name, id })}>
                {name}
              </Option>
            ))}
        </Options>
      </Transition>
    </Container>
  );
};

export default SwitchDropdown;
