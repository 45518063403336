import { types } from "../reducers";

// Proceedings
export const setProceedingsList = (list) => {
  return {
    type: types.SET_PROCEEDINGS_LIST,
    payload: list,
  };
};
export const updateProceedingStatus = (payload) => {
  return {
    type: types.UPDATE_PROCEEDING_STATUS,
    payload,
  };
};

// Sections
export const setSectionsList = (list) => {
  return {
    type: types.SET_SECTIONS_LIST,
    payload: list,
  };
};

export const createSection = (section) => {
  return {
    type: types.CREATE_SECTION,
    payload: section,
  };
};

// Resellers
export const setResellersList = (list) => {
  return {
    type: types.SET_RESELLERS_LIST,
    payload: list,
  };
};

// Users
export const setUsersList = (list) => {
  return {
    type: types.SET_USERS_LIST,
    payload: list,
  };
};
