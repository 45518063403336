import React from "react";
import styled from "styled-components";

const Box = styled.div`
  background-color: #f4f4f4;
  position: relative;
  width: 80%;
  min-width: 340px;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  border: 1px solid #e9ecf0;
  border-radius: 45px;
  box-shadow: 5px 5px 10px #000;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  user-select: none;

  #hole1 {
    top: 25px;
    left: 25px;
  }
  #hole2 {
    top: 25px;
    right: 25px;
  }
  #hole3 {
    bottom: 25px;
    left: 25px;
  }
  #hole4 {
    bottom: 25px;
    right: 25px;
  }

  .hole {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-image: radial-gradient(circle at 99%, #f4f4f4 10%, grey 70%);
    transform: rotate(45deg);
  }

  #header {
    background-color: #ef5350;
    padding: 20px;
    border-radius: 30px 30px 0 0;
    text-align: center;
  }

  h1 {
    text-transform: uppercase;
    color: #f4f4f4;
    font-size: 5.5em;
    line-height: 0.9em;
    letter-spacing: 3px;
    margin: 0;
    font-weight: 900;
  }

  .strike {
    position: absolute;
    width: 25%;
    height: 10px;
    background-color: #fff;
  }
  #strike1 {
    top: 115px;
    left: 80px;
  }
  #strike2 {
    top: 115px;
    right: 80px;
  }

  #sign-body {
    display: flex;
    flex-wrap: nowrap;
  }
  #copy-container {
    flex-basis: 60%;
  }
  #circle-container {
    flex-basis: 40%;
  }

  h2,
  p {
    text-align: center;
    color: #1d1e22;
  }

  h2 {
    font-size: 3em;
    text-transform: uppercase;
    margin: 40px 0;
    line-height: 0.9em;
  }
  p {
    font-size: 20px;
  }

  @media screen and (max-width: 930px) {
    #sign-wrapper {
      font-size: 0.75rem;
    }
  }
  @media screen and (max-width: 750px) {
    #sign-wrapper {
      font-size: 0.6rem;
    }
    h2 {
      margin: 25px 0;
    }
    .strike {
      visibility: hidden;
    }
  }
  @media screen and (max-width: 600px) {
    #sign-wrapper {
      font-size: 0.4rem;
      padding: 15px;
      border-radius: 25px;
    }
    #header {
      border-radius: 20px 20px 0 0;
    }
    #circle-container {
      width: 50%;
    }
    .hole {
      width: 10px;
      height: 10px;
    }
    :root {
      --hole-distance: 8px;
    }
  }
  @media screen and (max-width: 450px) {
    #sign-wrapper {
      font-size: 0.34rem;
    }
    h2 {
      margin: 10px;
    }
    p {
      font-size: 14px;
    }
  }
`;
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #36383f;
  font-size: 20px;
  padding-top: 40px;
`;

const Forbidden = () => {
  return (
    <Container>
      <Box>
        <div id="hole1" className="hole"></div>
        <div id="hole2" className="hole"></div>
        <div id="hole3" className="hole"></div>
        <div id="hole4" className="hole"></div>
        <header id="header">
          <h1>403 forbidden</h1>
          <div id="strike1" className="strike"></div>
          <div id="strike2" className="strike"></div>
        </header>
        <section id="sign-body">
          <div id="copy-container">
            <h2>Wymaga autoryzacji</h2>
            <p>
              <strong>Error 403: Forbidden</strong>. Nie masz wystarczających
              uprawnień, żeby zobaczyć tę stronę.
            </p>
          </div>
          <div id="circle-container">
            <svg
              version="1.1"
              viewBox="0 0 500 500"
              preserveAspectRatio="xMinYMin meet"
            >
              <defs>
                <pattern
                  id="image"
                  patternUnits="userSpaceOnUse"
                  height="450"
                  width="450"
                >
                  <image
                    x="25"
                    y="25"
                    height="450"
                    width="450"
                    href="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
                  ></image>
                </pattern>
              </defs>
              <circle
                cx="250"
                cy="250"
                r="200"
                strokeWidth="40px"
                stroke="#ef5350"
                fill="url(#image)"
              />
              <line
                x1="100"
                y1="100"
                x2="400"
                y2="400"
                strokeWidth="40px"
                stroke="#ef5350"
              />
            </svg>
          </div>
        </section>
      </Box>
    </Container>
  );
};

export default Forbidden;
