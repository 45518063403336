import React from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";

import { useAuthResellerDispatch } from "../../context/authReseller";
import { LOGIN_RESELLER } from "../../graphql/queries";

import { routes } from "../../routes";

import { ValidationInput } from "../../components/Input";
import Button from "../../components/Button";
import { Title } from "../../components/reseller/Title";

import sideImage from "../../assets/images/reseller_side_background.png";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;
const SideBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 560px;
`;
const BackgroundSide = styled.div`
  width: calc(100% - 560px);
  /* background-color: blue; */
  background-image: url(${sideImage});
  background-repeat: no-repeat;
  background-size: cover;
`;
const FormBox = styled.div`
  margin-left: auto;
  margin-right: 30px;
`;
const Form = styled.form`
  margin-bottom: 100px;
  width: 400px;
`;
const InputBox = styled.div`
  margin-bottom: 24px;
`;
const StyledTitle = styled(Title)`
  margin-bottom: 42px;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primaryBlue};
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: 14px;
`;

export default function Login() {
  const history = useHistory();
  const dispatch = useAuthResellerDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  const [loginReseller, { loading }] = useLazyQuery(LOGIN_RESELLER, {
    onError: (err) => {
      const errors = err.graphQLErrors[0].extensions.exception.errors;
      if (errors) {
        for (const item of Object.entries(errors)) {
          const [key, message] = item;
          setError(key, { type: "manual", message });
        }
      } else {
        console.log(err);
      }
    },
    onCompleted: ({ loginReseller }) => {
      if (loginReseller.forceChange) {
        const [nip, password] = getValues(["nip", "password"]);
        history.push({
          pathname: routes.reseller.forceChangePassword,
          state: { nip, password },
        });
      } else {
        dispatch({ type: "LOGIN", payload: loginReseller });
        history.push(routes.reseller.home);
      }
    },
  });

  const onSubmit = (data) => {
    loginReseller({ variables: data });
  };

  return (
    <Container>
      <SideBox>
        <FormBox>
          <StyledTitle>Logowanie</StyledTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputBox>
              <ValidationInput
                {...register("nip", {
                  required: { value: true, message: "Pole nie może być puste" },
                })}
                placeholder="Nip"
                error={errors?.nip?.message}
              />
            </InputBox>
            <InputBox>
              <ValidationInput
                {...register("password", {
                  required: { value: true, message: "Pole nie może być puste" },
                })}
                placeholder="Hasło"
                type="password"
                error={errors?.password?.message}
              />
            </InputBox>
            <ButtonBox>
              <StyledLink to={routes.reseller.recoveryPassword}>
                Zapomniałeś hasła?
              </StyledLink>
              <Button value="Zaloguj się" input isLoading={loading} />
            </ButtonBox>
          </Form>
        </FormBox>
      </SideBox>
      <BackgroundSide />
    </Container>
  );
}
