import React, { useState } from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";

import SwitchDropdown from "./SwitchDropdown";
import {
  useProceedingDispatch,
  useProceedingState,
} from "../context/proceedingState";
import { LIST_SECTIONS } from "../graphql/queries";
import { Input } from "./Input";
import { ProceedingStatusArray } from "../util/variables";

const OptionsPanel = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SwitchRightBox = styled.div`
  display: flex;
  align-items: center;
`;
const Box = styled.div`
  display: flex;
`;
const StyledInput = styled(Input)`
  height: 40px;
  font-size: 14px;
  min-width: 260px;
`;

export default function ResellerProceedingFiltration() {
  const state = useProceedingState();
  const dispatch = useProceedingDispatch();

  const [sections, setSections] = useState([]);
  const [listSections, { loading: sectionListLoading }] = useLazyQuery(
    LIST_SECTIONS,
    {
      onCompleted: ({ listSections }) => {
        const sections = listSections.map((section) => ({
          id: section.id,
          name: section.name,
        }));
        setSections(sections);
      },
      onError: (err) => console.log(err),
    }
  );

  return (
    <OptionsPanel>
      <Box>
        <StyledInput
          value={state.input}
          onChange={(e) => dispatch({ type: "input", payload: e.target.value })}
          placeholder="Nazwa lub nip end usera"
        />
      </Box>
      <Box>
        <SwitchRightBox>
          <SwitchDropdown
            items={sections}
            async={{ fetchItems: listSections, loading: sectionListLoading }}
            currentOption={state.section}
            setCurrentOption={(item) =>
              dispatch({ type: "section", payload: item })
            }
            reset={() => dispatch({ type: "RESET", payload: "section" })}
          />
          <SwitchDropdown
            items={ProceedingStatusArray}
            currentOption={state.status}
            setCurrentOption={(item) =>
              dispatch({ type: "status", payload: item })
            }
            reset={() => dispatch({ type: "RESET", payload: "status" })}
          />
        </SwitchRightBox>
      </Box>
    </OptionsPanel>
  );
}
