import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useQuery } from "@apollo/client";

import { GET_PROCEEDING } from "../../../graphql/queries";

import Button, { COLOR } from "../../../components/Button";
import { routes } from "../../../routes";

import MainTemplate from "../../../templates/admin/MainTemplate";
import { ProceedingStatus } from "../../../util/variables";
import useUpdateProceedingStatus from "../../../hooks/useUpdateProceedingStatus";
import { useCheckPermission } from "../../../hooks/useCheckPermission";
import { useAuthState } from "../../../context/auth";
import { defaultStyles, FileIcon } from "react-file-icon";
import { isEmpty } from "lodash";
import { replaceUrlProtocol } from "../../../util/urlFunctions";
import { format } from "date-fns";

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Content = styled.div`
  margin-top: 18px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 18px;
`;
const ElementBox = styled.div`
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.16);
`;
const Label = styled.div`
  font-weight: ${({ theme }) => theme.weight.semiBold};
  font-size: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
`;
const Description = styled.div`
  font-size: 16px;
  margin-bottom: 2px;
`;
const FilePreviewBox = styled.a`
  text-decoration: none;
  height: 100px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  min-height: 100px;
  object-fit: cover;
`;
const DescriptionFile = styled(Description)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 12px;
  margin-top: 14px;
  white-space: nowrap;
`;
const CommentItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 30px;
`;
const CommentsHistoryTitle = styled.h2``;
const CommentRowItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  column-gap: 20px;
`;
const CommentItem = styled.p`
  flex: ${({ isMessageItem }) => (isMessageItem ? 4 : 1)};
  font-weight: ${({ isAuthor }) => isAuthor && "bold"};
`;

export default function ProceedingShow({ match }) {
  const [showCommentsHistory, setShowCommentsHistory] = useState(false);
  const { user } = useAuthState();
  const { isAdmin } = useCheckPermission();

  const { id } = match.params;
  const history = useHistory();

  const { data: proceeding, loading } = useQuery(GET_PROCEEDING, {
    variables: { id },
  });
  const {
    status,
    clientName,
    clientNip,
    extensionCount,
    section,
    reseller,
    files,
    comments,
  } = proceeding?.getProceeding || {};

  const updateStatus = useUpdateProceedingStatus();

  return (
    <MainTemplate name="Podgląd projektu">
      <Buttons>
        {status === ProceedingStatus.WAITING && (
          <>
            {user.id === reseller.user.id && (
              <>
                <Button
                  margin="0 12px 0 0"
                  color={COLOR.GREEN}
                  onClick={() => {
                    updateStatus({ id, status: ProceedingStatus.OPEN });
                    history.goBack();
                  }}
                >
                  Akceptuj
                </Button>
                <Button
                  margin="0 12px 0 0"
                  color={COLOR.RED}
                  onClick={() => {
                    updateStatus({ id, status: ProceedingStatus.REJECTED });
                    history.goBack();
                  }}
                >
                  Odrzuć
                </Button>
              </>
            )}
          </>
        )}
        {status === ProceedingStatus.OPEN && isAdmin && (
          <Button
            margin="0 12px 0 0"
            color={COLOR.RED}
            onClick={() => {
              updateStatus({ id, status: ProceedingStatus.CLOSED });
              history.goBack();
            }}
          >
            Zamknij projekt
          </Button>
        )}
        {isAdmin && (
          <Button
            onClick={() =>
              history.push(`${routes.admin.proceeding}/${id}/edit`)
            }
          >
            Edytuj
          </Button>
        )}
      </Buttons>
      {!loading && (
        <>
          <Content>
            <ElementBox>
              <Label>Nazwa klienta:</Label>
              <Description>{clientName}</Description>
            </ElementBox>
            <ElementBox>
              <Label>Nip klienta:</Label>
              <Description>{clientNip}</Description>
            </ElementBox>
            <ElementBox>
              <Label>Sekcja:</Label>
              <Description>{section?.name}</Description>
            </ElementBox>
            <ElementBox>
              <Label>Reseller:</Label>
              <Description>{reseller?.name}</Description>
              <Description>{reseller?.email}</Description>
            </ElementBox>
            <ElementBox>
              <Label>Komentarz:</Label>
              <Description>
                {comments[0]?.message || "Brak komentarza"}
              </Description>
              {isAdmin && comments.length > 1 && !showCommentsHistory && (
                <Button
                  color={COLOR.GREY}
                  margin={"12px 0 0"}
                  style={{ width: "100%" }}
                  onClick={() => setShowCommentsHistory(true)}
                >
                  Pokaż historię komentarzy
                </Button>
              )}
            </ElementBox>
            <ElementBox>
              <Label>Ilość przedłużeń:</Label>
              <Description>{extensionCount - 1}</Description>
            </ElementBox>
            <ElementBox>
              <Label>Załączone pliki:</Label>
              <DescriptionFile>
                {isEmpty(files)
                  ? "Brak plików"
                  : files.map(({ url }) => {
                      let previewMarkdown;
                      url = replaceUrlProtocol(url);

                      if (url.match(/.(jpg|jpeg|png|gif)$/i)) {
                        previewMarkdown = (
                          <Image src={replaceUrlProtocol(url)} alt="Preview" />
                        );
                      } else {
                        const ext = url.split(".").pop();
                        previewMarkdown = (
                          <FileIcon extension={ext} {...defaultStyles[ext]} />
                        );
                      }
                      return (
                        <FilePreviewBox
                          key={url}
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          {previewMarkdown}
                        </FilePreviewBox>
                      );
                    })}
              </DescriptionFile>
            </ElementBox>
          </Content>
          <CommentItems>
            {showCommentsHistory && (
              <>
                <CommentsHistoryTitle>Historia komentarzy</CommentsHistoryTitle>
                {comments.map(({ message, author, createdAt, authorType }) => {
                  return (
                    <CommentRowItem key={createdAt}>
                      <CommentItem isAuthor>
                        {authorType === "USER" ? author.username : author.name}
                      </CommentItem>
                      <CommentItem isMessageItem>{message}</CommentItem>
                      <CommentItem>
                        {format(parseInt(createdAt), "dd-MM-yyyy HH:mm:ss")}
                      </CommentItem>
                    </CommentRowItem>
                  );
                })}
              </>
            )}
          </CommentItems>
        </>
      )}
    </MainTemplate>
  );
}
