export const getTruncatedUrl = (url) => {
  const arrayFromUrl = url.split("://");
  if (arrayFromUrl.length === 1) return arrayFromUrl[0];
  else if (arrayFromUrl.length > 1)
    return arrayFromUrl[arrayFromUrl.length - 1];
  else return null;
};

export const replaceUrlProtocol = (url) => {
  const truncatedUrl = getTruncatedUrl(url);
  if (!truncatedUrl) return url;

  let newUrl = url;
  if (window.location.protocol === "https:") newUrl = `https://${truncatedUrl}`;
  else newUrl = `http://${truncatedUrl}`;

  console.log(newUrl);

  return newUrl;
};
