const initialState = {
  proceedings: [],
  sections: [],
  resellers: [],
  users: [],
};

export const types = {
  SET_PROCEEDINGS_LIST: "SET_PROCEEDINGS_LIST",
  UPDATE_PROCEEDING_STATUS: "UPDATE_PROCEEDING_STATUS",
  SET_SECTIONS_LIST: "SET_SECTIONS_LIST",
  SET_RESELLERS_LIST: "SET_RESELLERS_LIST",
  SET_USERS_LIST: "SET_USERS_LIST",
  CREATE_SECTION: "CREATE_SECTION",
};

const rootReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_PROCEEDINGS_LIST:
      return {
        ...state,
        proceedings: payload,
      };
    case types.UPDATE_PROCEEDING_STATUS:
      return {
        ...state,
        proceedings: state.proceedings.map((proceeding) =>
          proceeding.id === payload.id
            ? { ...proceeding, status: payload.status }
            : proceeding
        ),
      };
    case types.SET_SECTIONS_LIST:
      return {
        ...state,
        sections: payload,
      };
    case types.SET_RESELLERS_LIST:
      return {
        ...state,
        resellers: payload,
      };
    case types.SET_USERS_LIST:
      return {
        ...state,
        users: payload,
      };
    case types.CREATE_SECTION:
      console.log([...state.sections, payload]);
      return {
        ...state,
        sections: [...state.sections, payload],
      };
    default:
      return state;
  }
};

export default rootReducer;
