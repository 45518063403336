import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { CREATE_SECTION, UPDATE_SECTION } from "../../../graphql/mutations";
import { GET_SECTION } from "../../../graphql/queries";

import MainTemplate from "../../../templates/admin/MainTemplate";
import { isUUID } from "../../../util/validators";
import { ValidationInput } from "../../../components/Input";
import Button, { COLOR } from "../../../components/Button";
import { routes } from "../../../routes";

const Form = styled.form``;
const InputBox = styled.div`
  width: calc(50% - 10px);
`;
const InputsBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function Section({ match }) {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const [isEdit, setIsEdit] = useState(false);

  if (isUUID(match.params.id) && !isEdit) {
    setIsEdit(true);
  }

  const { data, loading: fetchLoading } = useQuery(GET_SECTION, {
    variables: { id: match.params.id },
  });

  useEffect(() => {
    if (!fetchLoading && isEdit) {
      const section = data.getSection;
      setValue("name", section.name);
      setValue("expirationTime", section.expirationTime);
    }
  }, [fetchLoading, data, isEdit, setValue]);

  const [createSectionMutation, { loading: createLoading }] = useMutation(
    CREATE_SECTION,
    {
      onError: (err) => {
        console.log(err);
        setError("name", { type: "manual", message: err.message });
      },
      onCompleted() {
        toast.success("Dodano sekcję!", {
          autoClose: 3000,
        });
        history.push(routes.admin.sections);
      },
    }
  );

  const [updateSectionMutation, { loading: updateLoading }] = useMutation(
    UPDATE_SECTION,
    {
      onError: (err) => {
        setError("name", { type: "manual", message: err.message });
      },
      onCompleted() {
        toast.success("Zapisano!", {
          autoClose: 3000,
        });
        history.push(routes.admin.sections);
      },
    }
  );

  const onSubmit = (data) => {
    data.expirationTime = parseInt(data.expirationTime);
    if (isEdit) {
      updateSectionMutation({ variables: { ...data, id: match.params.id } });
    } else {
      createSectionMutation({ variables: data });
    }
  };

  return (
    <MainTemplate name={isEdit ? "Edycja sekcji" : "Tworzenie sekcji"}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsBox>
          <InputBox>
            <ValidationInput
              placeholder="Nazwa sekcji"
              label="Nazwa sekcji"
              {...register("name", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
              error={errors?.name?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
          <InputBox>
            <ValidationInput
              placeholder="Czas wygaśnięcia"
              label="Czas wygaśnięcia (w miesiącach)"
              {...register("expirationTime", {
                required: { value: true, message: "Pole nie może byc puste" },
                pattern: {
                  value: /^[0-9]+$/i,
                  message: "Podaj liczbę z zakresu 1-12",
                },
                min: { value: 1, message: "Podaj liczbę z zakresu 1-12" },
                max: { value: 12, message: "Podaj liczbę z zakresu 1-12" },
              })}
              error={errors?.expirationTime?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
        </InputsBox>
        <ButtonBox>
          <Button
            as={Link}
            to={routes.admin.sections}
            color={COLOR.GREY}
            margin="0 0 0 25px"
          >
            Anuluj
          </Button>
          <Button
            value={isEdit ? "Zapisz" : "Dodaj sekcję"}
            input
            isLoading={createLoading || updateLoading}
            margin="0 0 0 25px"
          />
        </ButtonBox>
      </Form>
    </MainTemplate>
  );
}
