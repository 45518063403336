import React, { useMemo, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { MdModeEdit } from "react-icons/md";
import { addMonths, format } from "date-fns";

import { routes } from "../../../routes";

import { setProceedingsList } from "../../../redux/actions";
import { LIST_PROCEEDINGS } from "../../../graphql/queries";

import MainTemplate from "../../../templates/admin/MainTemplate";
import DataTable from "../../../components/DataTable";
import StatusItem from "../../../components/StatusItem";
import ProceedingFiltration from "../../../components/ProceedingFiltration";
import { useProceedingState } from "../../../context/proceedingState";
import { usePagination } from "../../../hooks/usePagination";
import Pagination from "../../../components/Pagination";
import { useCheckPermission } from "../../../hooks/useCheckPermission";
import { truncateText } from "../../../util/functions";

const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;

export default function Proceedings() {
  const { isGuardian } = useCheckPermission();

  const columns = useMemo(() => {
    let cols = [
      {
        Header: "End user",
        accessor: "clientName",
      },
      {
        Header: "End user NIP",
        accessor: "clientNip",
      },
      {
        Header: "Data zgłoszenia",
        accessor: "createdAt",
      },
      {
        Header: "Data wygaśnięcia",
        accessor: "expirationDate",
      },
      {
        Header: "Przedłużenia",
        accessor: "extensionCount",
      },
      {
        Header: "Reseller",
        accessor: "resellerName",
      },
      {
        Header: "NIP Resellera",
        accessor: "resellerNip",
      },
      {
        Header: "Dotyczy",
        accessor: "section",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Opiekun",
        accessor: "guardianName",
      },
      {
        accessor: "edit",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ];

    if (isGuardian) {
      cols = cols.filter((obj) => obj.accessor !== "edit");
    }

    return cols;
  }, [isGuardian]);

  const { paginationState, paginationDispatch } = usePagination({ limit: 25 });

  const dataDispatch = useDispatch();
  const proceedingsState = useProceedingState();

  useEffect(() => {
    paginationDispatch({ type: "RESET" });
  }, [proceedingsState, paginationDispatch]);

  const { loading } = useQuery(LIST_PROCEEDINGS, {
    variables: {
      filter: {
        status: proceedingsState.status.id,
        sectionId: proceedingsState.section.id,
        resellerId: proceedingsState.reseller.id,
        input: proceedingsState.input,
        userId: proceedingsState.user.id,
      },
      offset: paginationState.offset,
      limit: paginationState.limit,
      order: proceedingsState.order,
    },
    onError: (err) => {
      console.log(err);
    },
    onCompleted(data) {
      const { items, count } = data.listProceedings;
      dataDispatch(setProceedingsList(items));
      paginationDispatch({ type: "COUNT", payload: count });
    },
  });

  const proceedings = useSelector((state) => state.proceedings);

  const data = proceedings?.reduce((acc, proceeding) => {
    const status = <StatusItem value={proceeding.status} />;

    acc = [
      ...acc,
      {
        resellerName: proceeding.reseller.name,
        resellerNip: proceeding.reseller.nip,
        status,
        clientName: (
          <Link
            to={`${routes.admin.proceeding}/${proceeding.id}/show`}
            title={proceeding.clientName}
          >
            {truncateText(proceeding.clientName)}
          </Link>
        ),
        clientNip: proceeding.clientNip,
        section: proceeding.section.name,
        createdAt: format(parseInt(proceeding.createdAt), "dd-MM-yyyy"),
        expirationDate: format(
          addMonths(
            parseInt(proceeding.createdAt),
            proceeding.section.expirationTime * proceeding.extensionCount
          ),
          "dd-MM-yyyy"
        ),
        guardianName: proceeding.reseller.user.username,
        extensionCount: proceeding.extensionCount - 1,
        edit: (
          <Link to={`${routes.admin.proceeding}/${proceeding.id}/edit`}>
            <MdModeEdit size={22} />
          </Link>
        ),
      },
    ];
    return acc;
  }, []);

  return (
    <MainTemplate name="Projekty">
      <ProceedingFiltration />
      <DataTable data={data} columns={columns} loading={loading} />
      <Pagination
        paginationState={paginationState}
        paginationDispatch={paginationDispatch}
      />
    </MainTemplate>
  );
}
