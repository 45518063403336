import { useMutation } from "@apollo/client";
import {
  CREATE_RESELLER,
  REMOVE_RESELLER,
  UPDATE_RESELLER,
} from "../../../../graphql/mutations";
import { routes } from "../../../../routes";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const useResellerActions = (setError) => {
  const history = useHistory();

  const updateReseller = useMutation(UPDATE_RESELLER, {
    onError: (err) => {
      const errors = err.graphQLErrors[0].extensions.exception.errors;
      if (errors) {
        for (const item of Object.entries(errors)) {
          const [key, message] = item;
          setError && setError(key, { type: "manual", message });
        }
      } else {
        console.log(err.graphQLErrors);
      }
    },
    onCompleted() {
      toast.success("Zapisano!", {
        autoClose: 3000,
      });
    },
  });

  const createReseller = useMutation(CREATE_RESELLER, {
    onError: (err) => {
      const errors = err.graphQLErrors[0].extensions.exception.errors;
      if (errors) {
        for (const item of Object.entries(errors)) {
          const [key, message] = item;
          setError && setError(key, { type: "manual", message });
        }
      } else {
        console.log(err);
      }
    },
    onCompleted() {
      toast.success("Zapisano!", {
        autoClose: 3000,
      });
      history.push(routes.admin.resellers);
    },
  });

  const removeReseller = useMutation(REMOVE_RESELLER, {
    onError: (err) => {
      toast.error("UPS! Coś poszło nie tak", {
        autoClose: 3000,
      });
      console.error(err);
    },
    onCompleted() {
      toast.success("Reseller usunięty!", {
        autoClose: 3000,
      });
      history.push(routes.admin.resellers);
    },
  });

  return {
    createReseller,
    updateReseller,
    removeReseller,
  };
};
