import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

    html {
      scroll-behavior: smooth;
    }

    *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body {
      font-family: 'muli', sans-serif;
        font-style: normal;
        font-weight: ${({ theme }) => theme.weight.medium};
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.darkGrey};
        overflow-x: hidden;
    }

    input, textarea, a, p {
      font-family: 'muli', sans-serif;
      text-decoration: none;
      outline: none;
      color: ${({ theme }) => theme.color.darkGrey}
    }
  `;
