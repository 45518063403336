import React from "react";
import { Route, Redirect } from "react-router-dom";

import { routes } from "../routes";
import { useAuthState } from "../context/auth";
import { useAuthResellerState } from "../context/authReseller";
import { UserPermission } from "../util/variables";
import Forbidden from "../pages/Forbidden";

export function DynamicAdminRoute({ permission, ...props }) {
  const { user } = useAuthState();

  const protectedRoute =
    permission === UserPermission.ADMIN ||
    permission === UserPermission.GUARDIAN;
  const open = permission === UserPermission.GUEST;

  if (user) {
    if (open) return <Redirect to={routes.admin.proceedings} />;
    else if (protectedRoute) {
      if (user.permission === UserPermission.ADMIN) {
        return <Route {...props} />;
      } else if (user.permission === UserPermission.GUARDIAN) {
        if (permission === UserPermission.GUARDIAN) {
          return <Route {...props} />;
        } else {
          return <Forbidden />;
        }
      }
    }
  } else {
    if (protectedRoute) return <Redirect to={routes.admin.login} />;
    else if (open) return <Route {...props} />;
  }
}

export function DynamicResellerRoute({ ...rest }) {
  const { reseller } = useAuthResellerState();

  let render;
  if (rest.guest && reseller) render = <Redirect to={routes.reseller.home} />;
  else if (rest.protect && !reseller)
    render = <Redirect to={routes.reseller.login} />;
  else render = <Route {...rest} />;

  return render;
}
