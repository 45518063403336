import React from "react";
import styled, { useTheme } from "styled-components";
import { Link } from "react-router-dom";

import { routes } from "../routes";
import { Title } from "../components/reseller/Title";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 30vh;
  overflow: hidden;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 98vw;
  width: 600px;
  margin-top: 30px;
`;
const BigButton = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  width: 280px;
  padding: 14px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.primaryBlue};
  color: white;
  transition: transform 0.12s ease-in-out;

  :hover {
    transform: scale(1.04);
  }
`;
const StyledBigButton = styled(BigButton)`
  background-color: ${({ theme }) => theme.color.lightBlue};
`;
const MainText = styled.div`
  font-weight: ${({ theme }) => theme.weight.semiBold};
  font-size: 20px;
  margin-bottom: 2px;
`;
const SmallText = styled.div`
  font-weight: ${({ theme }) => theme.weight.regular};
  font-size: 13px;
`;
const Svg = styled.div`
  position: absolute;
  top: -200px;
  right: -200px;
  width: 600px;
`;
const Svg2 = styled.div`
  position: absolute;
  left: -200px;
  bottom: -200px;
  width: 600px;
`;
const StyledTitle = styled(Title)`
  font-size: 30px;
`;

const Home = () => {
  const { color } = useTheme();

  return (
    <Container>
      <Svg>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill={color.lightBlue}
            d="M49.9,-36.4C65,-20.8,77.8,-0.1,75,19C72.1,38,53.6,55.4,32.7,63.9C11.8,72.4,-11.4,72.1,-25.6,61.9C-39.8,51.7,-45.1,31.6,-51.7,9.7C-58.4,-12.1,-66.4,-35.7,-58,-49.7C-49.6,-63.7,-24.8,-68.1,-3.7,-65.1C17.4,-62.2,34.8,-51.9,49.9,-36.4Z"
            transform="translate(100 100)"
          />
        </svg>
      </Svg>
      <Svg2>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill={color.primaryBlue}
            d="M37.8,-66.6C47.3,-60,52,-46.4,60.2,-34.1C68.3,-21.8,80,-10.9,81.6,1C83.3,12.8,75,25.7,65.1,35C55.3,44.4,43.9,50.3,32.7,55.2C21.6,60.2,10.8,64.2,-0.9,65.8C-12.7,67.4,-25.3,66.6,-38.4,62.8C-51.5,59,-65,52.2,-67.5,41.2C-70,30.2,-61.6,15.1,-62.1,-0.2C-62.5,-15.6,-71.8,-31.2,-69.2,-42.1C-66.6,-53,-52.2,-59.3,-38.7,-63.5C-25.2,-67.8,-12.6,-70.1,0.8,-71.4C14.2,-72.8,28.4,-73.2,37.8,-66.6Z"
            transform="translate(100 100)"
          />
        </svg>
      </Svg2>
      <StyledTitle>Witamy w Epson Tender Manager</StyledTitle>
      <Buttons>
        <BigButton to={routes.admin.login}>
          <MainText>Panel administratora</MainText>
          <SmallText>administratorzy, opiekunowie</SmallText>
        </BigButton>
        <StyledBigButton to={routes.reseller.home}>
          <MainText>Panel resellera</MainText>
          <SmallText>resellerzy</SmallText>
        </StyledBigButton>
      </Buttons>
    </Container>
  );
};

export default Home;
