import React from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";

import { SEND_USER_PASSWORD } from "../../graphql/mutations";

import { routes } from "../../routes";

import { ValidationInput } from "../../components/Input";
import Button, { COLOR } from "../../components/Button";

import EpsonLogo from "../../assets/svg/EpsonLogo";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
`;
const CenterBox = styled.div`
  margin: 190px auto 0;
  width: 460px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2), 0 0 12px 0 rgba(0, 0, 0, 0.1);
  padding: 55px 44px 52px;
  display: flex;
  flex-direction: column;
`;
const Form = styled.form``;
const InputBox = styled.div`
  margin-bottom: 20px;
`;
const LogoBox = styled.div`
  display: flex;
  margin-bottom: 37px;
  align-self: center;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function RecoveryPassword() {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const [setNewPassword, { loading }] = useMutation(SEND_USER_PASSWORD, {
    onError: (err) => {
      const errors = err.graphQLErrors[0].extensions.exception.errors;
      if (errors) {
        for (const item of Object.entries(errors)) {
          const [key, message] = item;
          setError(key, { type: "manual", message });
        }
      } else {
        console.log(err);
      }
    },
    onCompleted: () => {
      toast.success("Nowe hasło zostało wysłane na Twojego maila");
      history.push(routes.admin.login);
    },
  });

  const onSubmit = (data) => {
    setNewPassword({ variables: data });
  };

  return (
    <Container>
      <CenterBox>
        <LogoBox>
          <EpsonLogo width={133} />
        </LogoBox>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputBox>
            <ValidationInput
              {...register("email", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
              placeholder="Email"
              error={errors?.email?.message}
            />
          </InputBox>
          <ButtonBox>
            <Button color={COLOR.GREY} as={Link} to={routes.admin.login}>
              Wróć
            </Button>
            <Button value="Wyślij nowe hasło" input isLoading={loading} />
          </ButtonBox>
        </Form>
      </CenterBox>
    </Container>
  );
}
