import React from "react";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";

import { useAuthDispatch } from "../../context/auth";
import { LOGIN_USER } from "../../graphql/queries";

import { routes } from "../../routes";

import { ValidationInput } from "../../components/Input";
import Button from "../../components/Button";

import EpsonLogo from "../../assets/svg/EpsonLogo";

const Container = styled.div`
  width: 100%;
`;
const CenterBox = styled.div`
  margin: 190px auto 0;
  width: 460px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2), 0 0 12px 0 rgba(0, 0, 0, 0.1);
  padding: 55px 44px 52px;
  display: flex;
  flex-direction: column;
`;
const Form = styled.form``;
const InputBox = styled.div`
  margin-bottom: 20px;
`;
const LogoBox = styled.div`
  display: flex;
  margin-bottom: 37px;
  align-self: center;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primaryBlue};
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: 14px;
`;

export default function Login() {
  const history = useHistory();
  const dispatch = useAuthDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  const [loginUser, { loading }] = useLazyQuery(LOGIN_USER, {
    onError: (err) => {
      const errors = err.graphQLErrors[0].extensions.exception.errors;
      if (errors) {
        for (const item of Object.entries(errors)) {
          const [key, message] = item;
          setError(key, { type: "manual", message });
        }
      } else {
        console.log(err);
      }
    },
    onCompleted: ({ login }) => {
      if (login.forceChange) {
        const [email, password] = getValues(["email", "password"]);
        history.push({
          pathname: routes.admin.forceChangePassword,
          state: { email, password },
        });
      } else {
        dispatch({ type: "LOGIN", payload: login });
        history.push(routes.admin.proceedings);
      }
    },
  });

  const onSubmit = (data) => {
    loginUser({ variables: data });
  };

  return (
    <Container>
      <CenterBox>
        <LogoBox>
          <EpsonLogo width={133} />
        </LogoBox>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputBox>
            <ValidationInput
              {...register("email", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
              placeholder="Login"
              error={errors?.email?.message}
            />
          </InputBox>
          <InputBox>
            <ValidationInput
              {...register("password", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
              placeholder="Hasło"
              type="password"
              error={errors?.password?.message}
            />
          </InputBox>
          <ButtonBox>
            <StyledLink to={routes.admin.recoveryPassword}>
              Zapomniałeś hasła?
            </StyledLink>
            <Button value="Zaloguj się" input isLoading={loading} />
          </ButtonBox>
        </Form>
      </CenterBox>
    </Container>
  );
}
