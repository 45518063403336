import React from "react";
import styled from "styled-components";
import { ProceedingStatusTranslations } from "../util/variables";

const Item = styled.div`
  display: block;
  width: fit-content;
  text-transform: uppercase;
  padding: 3px 8px;
  background-color: ${({ color }) => color};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.weight.bold};
  color: white;
  border-radius: 5px;
  margin: 2px 4px 2px 0;
  user-select: none;
`;

export default function StatusItem({ value }) {
  const color = ProceedingStatusTranslations[value].color;

  return <Item color={color}>{ProceedingStatusTranslations[value].name}</Item>;
}
