import React from "react";
import styled, { css } from "styled-components";
import { useSelect } from "downshift";
import { RiArrowDropDownLine } from "react-icons/ri";

import Loader from "./Loader";
import { Label } from "./admin/Label";

const Container = styled.div`
  position: relative;

  * {
    outline: none;
  }
`;
const SelectedItem = styled.div`
  position: relative;
  width: 100%;
  border: none;
  border-radius: 4px;
  box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.16);
  user-select: none;
  padding: 11px 18px 10px;
  background-color: "#fff";
  color: ${({ theme, error }) =>
    error ? theme.color.lightRed : theme.color.grey};
  font-size: 18px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  cursor: pointer;

  ${({ theme, isLoading }) =>
    isLoading &&
    css`
      background-color: ${theme.color.lightGrey};
      opacity: 0.6;
      pointer-events: none;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      user-select: none;
      opacity: 0.5;
      cursor: unset;
    `}
`;
const DropdownList = styled.ul`
  position: absolute;
  list-style: none;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  background-color: white;
  border-radius: 0 0 4px 4px;
  box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.05);
  max-height: 220px;
  overflow-y: auto;
  z-index: 3;
`;
const Element = styled.li`
  color: ${({ theme }) => theme.color.grey};
  background-color: ${({ theme, isSelected }) =>
    isSelected && theme.color.lightBlueOpacity10};
  padding: 11px 18px 10px;
  cursor: pointer;
`;
const Error = styled.div`
  position: absolute;
  bottom: 0px;
  left: 4px;
  transform: translateY(100%);
  font-size: 12px;
  color: ${({ theme }) => theme.color.red};
  font-weight: ${({ theme }) => theme.weight.regular};
`;
const LoaderBox = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  z-index: 5;
`;
const IconBox = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  display: flex;
  transition: 0.18s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(-50%) rotate(180deg);
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      display: none;
    `}
`;
const LoadingBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

const Select = ({
  field: { onChange, value },
  label,
  items,
  isLoading,
  error,
  isListLoading,
  getItems,
  name,
  placeholder,
  disabled,
}) => {
  const itemToString = (item) => item.name;

  const {
    isOpen,
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    selectedItem: value || {
      id: null,
      name: placeholder || "Wybierz z listy",
    },
    onSelectedItemChange: (e) => onChange(e.selectedItem),
    itemToString,
    onIsOpenChange: () => {
      if (!items.length > 0 && !isOpen) {
        getItems();
      }
    },
  });

  return (
    <Container>
      {label && <Label htmlFor={name}>{label}</Label>}
      <SelectedItem {...getToggleButtonProps({ isLoading, error, disabled })}>
        {itemToString(selectedItem)}
        <IconBox isOpen={isOpen} isLoading={isLoading}>
          <RiArrowDropDownLine size={35} />
        </IconBox>
      </SelectedItem>
      <DropdownList {...getMenuProps()}>
        {isOpen &&
          (!isListLoading ? (
            items.map((item, index) => (
              <Element
                key={`${item.id}${index}`}
                {...getItemProps({
                  item,
                  index,
                  isSelected: highlightedIndex === index,
                })}
              >
                {item.name}
              </Element>
            ))
          ) : (
            <LoadingBox>
              <Loader />
            </LoadingBox>
          ))}
      </DropdownList>
      {isLoading && (
        <LoaderBox>
          <Loader loading={isLoading} size={16} />
        </LoaderBox>
      )}
      {error && <Error>{error.charAt(0).toUpperCase() + error.slice(1)}</Error>}
    </Container>
  );
};

export default Select;
