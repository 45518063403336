import React from "react";
import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 4px 12px;
  font-size: 13px;
  letter-spacing: 0.4px;
  font-weight: ${({ theme }) => theme.weight.bold};
`;
const Info = styled.div`
  color: ${({ theme }) => theme.color.grey};
  user-select: none;
`;
const Buttons = styled.div`
  display: flex;
`;
const Button = styled.div`
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.grey : theme.color.primaryBlue};
  margin-left: 12px;
  cursor: ${({ disabled }) => !disabled && "pointer"};
  user-select: none;
`;

const Pagination = ({ paginationState, paginationDispatch }) => {
  const { offset, limit, count } = paginationState;
  const disableNext = offset + limit >= count;
  const disablePrev = offset - limit < 0;

  return (
    <PaginationContainer>
      <Info>{`Strona ${offset / limit + 1} z ${Math.ceil(
        count / limit
      )}`}</Info>
      <Buttons>
        <Button
          onClick={() => paginationDispatch({ type: "PREV" })}
          disabled={disablePrev}
        >
          Poprzednia
        </Button>
        <Button
          onClick={() => paginationDispatch({ type: "NEXT" })}
          disabled={disableNext}
        >
          Następna
        </Button>
      </Buttons>
    </PaginationContainer>
  );
};

export default Pagination;
