import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { UPDATE_PROCEEDING } from "../graphql/mutations";
import { updateProceedingStatus } from "../redux/actions";

const useUpdateProceedingStatus = () => {
  const dataDispatch = useDispatch();

  const [mutation] = useMutation(UPDATE_PROCEEDING, {
    onError: (err) => {
      console.log(err);
      toast.error("Ups, coś poszło nie tak!", {
        autoClose: 3000,
      });
    },
    onCompleted({ updateProceeding }) {
      dataDispatch(
        updateProceedingStatus({
          id: updateProceeding.id,
          status: updateProceeding.status,
        })
      );
      toast.success("Status zmieniony pomyślnie!", {
        autoClose: 3000,
      });
    },
  });

  const updateStatus = (variables) => {
    mutation({ variables });
  };

  return updateStatus;
};

export default useUpdateProceedingStatus;
