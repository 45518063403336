import React, { useMemo } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdModeEdit } from "react-icons/md";

import { setSectionsList } from "../../../redux/actions";
import { LIST_SECTIONS } from "../../../graphql/queries";

import MainTemplate from "../../../templates/admin/MainTemplate";
import DataTable from "../../../components/DataTable";
import AddButton from "../../../components/AddButton";
import { routes } from "../../../routes";
import { useCheckPermission } from "../../../hooks/useCheckPermission";

const Element = styled.div``;
const OptionsPanel = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
`;
const FlexEndCell = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    display: flex;
  }
`;

export default function Sections() {
  const dataDispatch = useDispatch();
  const { isGuardian, isAdmin } = useCheckPermission();

  const { loading } = useQuery(LIST_SECTIONS, {
    onError: (err) => {
      console.log(err);
    },
    onCompleted(data) {
      dataDispatch(setSectionsList(data.listSections));
    },
  });

  const sections = useSelector((state) => state.sections);

  let columns = useMemo(() => {
    let cols = [
      {
        Header: "Nazwa sekcji",
        accessor: "name",
      },
      {
        Header: "Czas wygaśnięcia",
        accessor: "expirationTime",
      },
      {
        accessor: "edit",
        Cell: ({ cell: { value } }) => <FlexEndCell>{value}</FlexEndCell>,
      },
    ];
    if (isGuardian) {
      cols = cols.filter((obj) => obj.accessor !== "edit");
    }
    return cols;
  }, [isGuardian]);

  const data = sections.reduce((acc, section) => {
    acc = [
      ...acc,
      {
        name: section.name,
        expirationTime: <Element>{`${section.expirationTime} msc`}</Element>,
        edit: (
          <Link to={`${routes.admin.section}/${section.id}`}>
            <MdModeEdit size={22} />
          </Link>
        ),
      },
    ];
    return acc;
  }, []);

  return (
    <MainTemplate name="Sekcje">
      {isAdmin && (
        <OptionsPanel>
          <AddButton as={Link} to={`${routes.admin.section}/create`} />
        </OptionsPanel>
      )}
      <DataTable data={data} columns={columns} loading={loading} />
    </MainTemplate>
  );
}
