import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { AiFillCarryOut, AiFillGold } from "react-icons/ai";
import { RiTimeFill } from "react-icons/ri";
import { FaUserFriends, FaUsersCog } from "react-icons/fa";

import { routes } from "../../routes";
import { theme } from "../../theme/theme";

const iconStyles = { width: "22px", height: "22px" };

const NavBox = styled.div`
  position: sticky;
  top: 50px;
  width: 240px;
  height: calc(100vh - 50px);
  box-shadow: -19px 0px 20px 8px #333;
`;
const Link = styled(NavLink)`
  display: flex;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  transition: background 0.2s ease-in-out;

  &.selected,
  :hover {
    background-color: ${({ theme }) => theme.color.lightGrey};
  }
`;
const Text = styled.div`
  margin-left: 14px;
  letter-spacing: 0.4px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  color: ${({ theme }) => theme.color.darkGrey};
`;

export default function Navigation() {
  const createLink = ({ name, icon: Icon, route }) => (
    <Link exact to={route} activeClassName="selected">
      <Icon style={iconStyles} color={theme.color.darkGrey} />
      <Text>{name}</Text>
    </Link>
  );

  return (
    <NavBox>
      {createLink({
        name: "Projekty",
        route: routes.admin.proceedings,
        icon: AiFillCarryOut,
      })}
      {createLink({
        name: "Projekty oczekujące",
        route: routes.admin.proceedingsWaiting,
        icon: RiTimeFill,
      })}
      {createLink({
        name: "Sekcje",
        route: routes.admin.sections,
        icon: AiFillGold,
      })}
      {createLink({
        name: "Resellerzy",
        route: routes.admin.resellers,
        icon: FaUserFriends,
      })}
      {createLink({
        name: "Użytkownicy",
        route: routes.admin.users,
        icon: FaUsersCog,
      })}
    </NavBox>
  );
}
