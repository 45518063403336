import React, { createContext, useReducer, useContext } from "react";
import jwtDecode from "jwt-decode";

const AuthResellerStateContext = createContext();
const AuthResellerDispatchContext = createContext();

let reseller = null;
const token = localStorage.getItem("reseller_token");
if (token) {
  const decodedToken = jwtDecode(token);
  const expiresAt = new Date(decodedToken.exp * 1000);

  if (new Date() > expiresAt) {
    localStorage.removeItem("reseller_token");
  } else {
    reseller = decodedToken;
  }
} else console.log("No reseller token found");

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("reseller_token", action.payload.token);
      return {
        ...state,
        reseller: action.payload,
      };
    case "LOGOUT":
      localStorage.removeItem("reseller_token");
      return {
        ...state,
        reseller: null,
      };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export const AuthResellerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { reseller });

  return (
    <AuthResellerDispatchContext.Provider value={dispatch}>
      <AuthResellerStateContext.Provider value={state}>
        {children}
      </AuthResellerStateContext.Provider>
    </AuthResellerDispatchContext.Provider>
  );
};

export const useAuthResellerState = () => useContext(AuthResellerStateContext);
export const useAuthResellerDispatch = () =>
  useContext(AuthResellerDispatchContext);
