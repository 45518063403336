import { useReducer } from "react";

export const usePagination = (defaultState) => {
  const paginationReducer = (state, action) => {
    let { offset, limit, count } = state;

    const nextCondition = offset + limit >= count;
    const prevCondition = offset - limit < 0;

    switch (action.type) {
      case "NEXT":
        if (nextCondition) return state;
        return {
          ...state,
          offset: offset + limit,
        };

      case "PREV":
        if (prevCondition) return state;
        return {
          ...state,
          offset: offset - limit,
        };

      case "COUNT":
        return {
          ...state,
          count: action.payload,
        };

      case "RESET":
        return {
          ...state,
          offset: 0,
        };

      default:
        console.log("Bad type");
    }
  };

  const [state, dispatch] = useReducer(paginationReducer, {
    offset: 0,
    limit: 20,
    count: null,
    ...defaultState,
  });

  return {
    paginationState: state,
    paginationDispatch: dispatch,
  };
};
