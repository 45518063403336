import React from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

import Loader from "./Loader";
import {
  useProceedingDispatch,
  useProceedingState,
} from "../context/proceedingState";

const Box = styled.div`
  box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  overflow-x: auto;
  width: 100%;
`;
const Table = styled.table`
  min-width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  text-align: left;
  margin: none;

  thead {
    th {
      height: 50px;
      background-color: ${({ theme }) => theme.color.primaryBlue};
      color: white;
      font-weight: ${({ theme }) => theme.weight.bold};
      font-size: 16px;
      white-space: nowrap;

      & > span {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }

    th:first-of-type {
      border-top-left-radius: 4px;
    }
    th:last-of-type {
      border-top-right-radius: 4px;
    }
  }

  tbody {
    td {
      height: 48px;
      font-size: 16px;
      color: ${({ theme }) => theme.color.darkGrey};
      font-weight: ${({ theme }) => theme.weight.semiBold};
      white-space: nowrap;
    }

    tr {
      transition: 0.18s ease-in-out;

      :hover {
        background-color: ${({ theme }) => theme.color.lightBlueOpacity10};
      }
    }

    tr:last-of-type td:first-of-type {
      border-bottom-left-radius: 4px;
    }
    tr:last-of-type td:last-of-type {
      border-bottom-right-radius: 4px;
    }
  }

  td,
  th {
    padding: 8px 16px;
  }

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.color.greyOpacity10};
  }
`;

const CenterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
`;

const allowedSortingCols = ["clientName", "createdAt", "status"];

export default function DataTable({ data, columns, loading }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const proceedingState = useProceedingState();
  const proceedingDispatch = useProceedingDispatch();

  let tableInfo;
  if (!rows || loading) {
    tableInfo = (
      <CenterBox>
        <Loader />
      </CenterBox>
    );
  } else if (rows.length === 0) {
    tableInfo = (
      <CenterBox>
        <p>Brak danych</p>
      </CenterBox>
    );
  } else if (rows.length > 0) {
    tableInfo = null;
  }

  const handleSortingClick = (id) => {
    if (!allowedSortingCols.includes(id)) return;

    proceedingDispatch({
      type: "SET_ORDER",
      payload: id,
    });
  };

  const renderSortingArrow = (id) => {
    if (proceedingState.order[0] === id) {
      if (proceedingState.order[1] === "ASC") return <FaArrowDown size={12} />;
      else return <FaArrowUp size={12} />;
    }
  };

  return (
    <Box>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={
                    allowedSortingCols.includes(column.id)
                      ? {
                          cursor: "pointer",
                        }
                      : {}
                  }
                  onClick={() => handleSortingClick(column.id)}
                >
                  <span>
                    {column.render("Header")}
                    {renderSortingArrow(column.id)}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!tableInfo &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </Table>
      {tableInfo}
    </Box>
  );
}
