import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { routes } from "../routes";

import AddButton from "./AddButton";
import SwitchDropdown from "./SwitchDropdown";
import {
  useProceedingDispatch,
  useProceedingState,
} from "../context/proceedingState";
import { LIST_RESELLERS, LIST_SECTIONS, LIST_USERS } from "../graphql/queries";
import { Input } from "./Input";
import { ProceedingStatusArray } from "../util/variables";
import { useCheckPermission } from "../hooks/useCheckPermission";

const OptionsPanel = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SwitchRightBox = styled.div`
  display: flex;
  align-items: center;
`;
const Box = styled.div`
  display: flex;
`;
const StyledInput = styled(Input)`
  height: 40px;
  font-size: 14px;
  min-width: 260px;
`;

export default function ProceedingFiltration() {
  const state = useProceedingState();
  const dispatch = useProceedingDispatch();
  const { isAdmin } = useCheckPermission();

  const [sections, setSections] = useState([]);
  const [listSections, { loading: sectionListLoading }] = useLazyQuery(
    LIST_SECTIONS,
    {
      onCompleted: ({ listSections }) => {
        const sections = listSections.map((section) => ({
          id: section.id,
          name: section.name,
        }));
        setSections(sections);
      },
      onError: (err) => console.log(err),
    }
  );

  const [resellers, setResellers] = useState([]);
  const [listResellers, { loading: resellerListLoading }] = useLazyQuery(
    LIST_RESELLERS,
    {
      onCompleted: ({ listResellers }) => {
        const resellers = listResellers.map((reseller) => ({
          id: reseller.id,
          name: reseller.name,
        }));
        setResellers(resellers);
      },
      onError: (err) => console.log(err),
    }
  );

  const [users, setUsers] = useState([]);
  const [listUsers, { loading: userListLoading }] = useLazyQuery(LIST_USERS, {
    onCompleted: ({ listUsers }) => {
      const users = listUsers.map((user) => ({
        id: user.id,
        name: user.username,
      }));
      setUsers(users);
    },
    onError: (err) => console.log(err),
  });

  return (
    <OptionsPanel>
      <Box>
        <StyledInput
          value={state.input}
          onChange={(e) => dispatch({ type: "input", payload: e.target.value })}
          placeholder="Nazwa lub nip end usera"
        />
      </Box>
      <Box>
        <SwitchRightBox>
          <SwitchDropdown
            items={users}
            async={{ fetchItems: listUsers, loading: userListLoading }}
            currentOption={state.user}
            setCurrentOption={(item) =>
              dispatch({ type: "user", payload: item })
            }
            reset={() => dispatch({ type: "RESET", payload: "user" })}
            searchable
          />
          <SwitchDropdown
            items={resellers}
            async={{ fetchItems: listResellers, loading: resellerListLoading }}
            currentOption={state.reseller}
            setCurrentOption={(item) =>
              dispatch({ type: "reseller", payload: item })
            }
            reset={() => dispatch({ type: "RESET", payload: "reseller" })}
            searchable
          />
          <SwitchDropdown
            items={sections}
            async={{ fetchItems: listSections, loading: sectionListLoading }}
            currentOption={state.section}
            setCurrentOption={(item) =>
              dispatch({ type: "section", payload: item })
            }
            reset={() => dispatch({ type: "RESET", payload: "section" })}
          />
          <SwitchDropdown
            items={ProceedingStatusArray}
            currentOption={state.status}
            setCurrentOption={(item) =>
              dispatch({ type: "status", payload: item })
            }
            reset={() => dispatch({ type: "RESET", payload: "status" })}
          />
        </SwitchRightBox>
        {isAdmin ? (
          <AddButton as={Link} to={`${routes.admin.proceeding}/create`} />
        ) : null}
      </Box>
    </OptionsPanel>
  );
}
