import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CREATE_PROCEEDING,
  UPDATE_PROCEEDING,
} from "../../../graphql/mutations";
import {
  GET_PROCEEDING,
  LIST_RESELLERS,
  LIST_SECTIONS,
} from "../../../graphql/queries";

import MainTemplate from "../../../templates/admin/MainTemplate";
import { isUUID } from "../../../util/validators";
import { ValidationInput } from "../../../components/Input";
import Button, { COLOR } from "../../../components/Button";
import Select from "../../../components/Select";
import { routes } from "../../../routes";
import {
  ProceedingStatus,
  ProceedingStatusArray,
  ProceedingStatusTranslations,
} from "../../../util/variables";
import { useCheckPermission } from "../../../hooks/useCheckPermission";

const Form = styled.form``;
const InputBox = styled.div`
  width: calc(50% - 10px);
`;
const InputsBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function Proceeding({ match }) {
  const history = useHistory();
  const { isGuardian } = useCheckPermission();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, dirtyFields },
  } = useForm();

  const [isEdit, setIsEdit] = useState(false);

  if (isUUID(match.params.id) && !isEdit) {
    setIsEdit(true);
  }

  const { data, loading: fetchLoading } = useQuery(GET_PROCEEDING, {
    variables: { id: match.params.id },
  });

  const [resellers, setResellers] = useState([]);
  const [listResellers, { loading: resellerListLoading }] = useLazyQuery(
    LIST_RESELLERS,
    {
      onCompleted: ({ listResellers }) => {
        const resellers = listResellers.map((reseller) => ({
          id: reseller.id,
          name: reseller.name,
          nip: reseller.nip,
        }));
        setResellers(resellers);
      },
      onError: (err) => console.log(err),
    }
  );

  const [sections, setSections] = useState([]);
  const [listSections, { loading: sectionListLoading }] = useLazyQuery(
    LIST_SECTIONS,
    {
      onCompleted: ({ listSections }) => {
        const sections = listSections.map((section) => ({
          id: section.id,
          name: section.name,
        }));
        setSections(sections);
      },
      onError: (err) => console.log(err),
    }
  );

  useEffect(() => {
    if (!fetchLoading && isEdit) {
      const { clientName, clientNip, reseller, section, status, comments } =
        data.getProceeding;

      setValue("clientName", clientName);
      setValue("nip", clientNip);
      setValue("section", { id: section.id, name: section.name });
      setValue("comment", comments[0]?.message || "");
      setValue("reseller", { id: reseller.id, name: reseller.name });
      setValue("status", {
        id: status,
        name: ProceedingStatusTranslations[status].name,
      });
    } else if (!fetchLoading) {
      setValue("status", {
        id: ProceedingStatus.OPEN,
        name: ProceedingStatusTranslations[ProceedingStatus.OPEN].name,
      });
    }
  }, [fetchLoading, data, isEdit, setValue]);

  const [createProceedingMutation, { loading: createLoading }] = useMutation(
    CREATE_PROCEEDING,
    {
      onError: (err) => {
        const errors = err.graphQLErrors[0].extensions.exception.errors;
        if (errors) {
          for (const item of Object.entries(errors)) {
            const [key, message] = item;
            setError(key, { type: "manual", message });
          }
        } else {
          console.log(err);
        }
      },
      onCompleted() {
        toast.success("Dodano nowy projekt!", {
          autoClose: 3000,
        });
        history.push(routes.admin.proceedings);
      },
    }
  );

  const [updateProceedingMutation, { loading: updateLoading }] = useMutation(
    UPDATE_PROCEEDING,
    {
      onError: (err) => {
        const errors = err.graphQLErrors[0].extensions.exception.errors;
        if (errors) {
          for (const item of Object.entries(errors)) {
            const [key, message] = item;
            setError(key, { type: "manual", message });
          }
        } else {
          console.log(err);
        }
      },
      onCompleted() {
        toast.success("Zapisano!", {
          autoClose: 3000,
        });
      },
    }
  );

  const onSubmit = (data) => {
    const variables = {
      status: data.status.id,
      clientName: data.clientName,
      clientNip: data.nip,
      comment: dirtyFields.comment ? data.comment : undefined,
      resellerId: data.reseller.id,
      sectionId: data.section.id,
    };

    if (isEdit) {
      updateProceedingMutation({
        variables: { ...variables, id: match.params.id },
      });
    } else {
      createProceedingMutation({ variables });
    }
  };

  return (
    <MainTemplate name={isEdit ? "Edycja projektu" : "Tworzenie projektu"}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputsBox>
          <InputBox>
            <ValidationInput
              placeholder="Wprowadź nazwę end usera"
              label="Nazwa end usera"
              {...register("clientName", {
                required: { value: true, message: "Pole nie może byc puste" },
              })}
              error={errors?.clientName?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
          <InputBox>
            <ValidationInput
              placeholder="Wprowadź nip end usera"
              label="Nip end usera"
              {...register("nip", {
                required: { value: true, message: "Pole nie może byc puste" },
              })}
              error={errors?.nip?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
        </InputsBox>
        <InputsBox>
          <InputBox>
            <Controller
              render={({ ref, ...rest }) => (
                <Select
                  isLoading={fetchLoading}
                  items={
                    isGuardian
                      ? resellers.filter(
                          (reseller) => reseller.nip === "0000000000"
                        )
                      : resellers
                  }
                  getItems={listResellers}
                  isListLoading={resellerListLoading}
                  label="Reseller"
                  placeholder="Wybierz resellera"
                  error={errors?.reseller?.message}
                  {...rest}
                />
              )}
              rules={{
                required: { value: true, message: "Pole nie może byc puste" },
              }}
              control={control}
              name="reseller"
            />
          </InputBox>
          <InputBox>
            <Controller
              render={({ ref, ...rest }) => (
                <Select
                  isLoading={fetchLoading}
                  items={sections}
                  getItems={listSections}
                  isListLoading={sectionListLoading}
                  label="Sekcja"
                  placeholder="Wybierz sekcje"
                  error={errors?.section?.message}
                  {...rest}
                />
              )}
              rules={{
                required: { value: true, message: "Pole nie może byc puste" },
              }}
              control={control}
              name="section"
            />
          </InputBox>
        </InputsBox>
        <InputsBox>
          <InputBox>
            <Controller
              render={({ ref, ...rest }) => (
                <Select
                  items={ProceedingStatusArray}
                  isLoading={fetchLoading}
                  label="Status projektu"
                  placeholder="Wybierz aktualny status"
                  error={errors?.status?.message}
                  {...rest}
                />
              )}
              rules={{
                required: { value: true, message: "Pole nie może byc puste" },
              }}
              control={control}
              name="status"
            />
          </InputBox>
          <InputBox>
            <ValidationInput
              as="textarea"
              rows="4"
              placeholder="Wprowadź komentarz"
              label="Komentarz"
              {...register("comment")}
              error={errors?.comment?.message}
              isLoading={fetchLoading}
            />
          </InputBox>
        </InputsBox>
        <ButtonBox>
          <Button
            as={Link}
            to={routes.admin.proceedings}
            color={COLOR.GREY}
            margin="0 0 0 25px"
          >
            Anuluj
          </Button>
          <Button
            value={isEdit ? "Zapisz" : "Dodaj projekt"}
            input
            isLoading={createLoading || updateLoading}
            margin="0 0 0 25px"
          />
        </ButtonBox>
      </Form>
    </MainTemplate>
  );
}
