import React from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { routes } from "../../routes";

import { FORCE_CHANGE_USER_PASSWORD } from "../../graphql/mutations";
import { ValidationInput } from "../../components/Input";
import Button from "../../components/Button";

import EpsonLogo from "../../assets/svg/EpsonLogo";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
`;
const CenterBox = styled.div`
  margin: 190px auto 0;
  width: 460px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2), 0 0 12px 0 rgba(0, 0, 0, 0.1);
  padding: 55px 44px 52px;
  display: flex;
  flex-direction: column;
`;
const Form = styled.form``;
const InputBox = styled.div`
  margin-bottom: 20px;
`;
const LogoBox = styled.div`
  display: flex;
  margin-bottom: 37px;
  align-self: center;
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ForceChangePassword = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const [changePassword, { loading }] = useMutation(
    FORCE_CHANGE_USER_PASSWORD,
    {
      onError: (err) => {
        const errors = err.graphQLErrors[0].extensions.exception.errors;
        if (errors) {
          for (const item of Object.entries(errors)) {
            const [key, message] = item;
            setError(key, { type: "manual", message });
          }
        } else {
          toast.error("Ups! Coś poszło nie tak");
          console.log(err);
        }
      },
      onCompleted: () => {
        toast.success("Hasło zmienione pomyślnie");
        history.push(routes.admin.login);
      },
    }
  );

  const onSubmit = (data) => {
    changePassword({ variables: { ...data, ...history.location.state } });
  };

  return (
    <Container>
      <CenterBox>
        <LogoBox>
          <EpsonLogo width={133} />
        </LogoBox>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputBox>
            <ValidationInput
              {...register("newPassword", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
              placeholder="Nowe hasło"
              type="password"
              error={errors?.newPassword?.message}
            />
          </InputBox>
          <InputBox>
            <ValidationInput
              {...register("confirmNewPassword", {
                required: { value: true, message: "Pole nie może być puste" },
              })}
              placeholder="Potwierdź nowe hasło"
              type="password"
              error={errors?.confirmNewPassword?.message}
            />
          </InputBox>
          <ButtonBox>
            <Button value="Zmień hasło" input isLoading={loading} />
          </ButtonBox>
        </Form>
      </CenterBox>
    </Container>
  );
};

export default ForceChangePassword;
